import React, { useEffect } from "react"
import echarts from 'echarts'
import { css } from "glamor"


export default function EchartsRing(props) {

    const drawRing = () => {
        const myEchart = echarts.init(document.getElementById(props.ringId))
        var placeHolderStyle = {
            normal: {
                label: {
                    show: false
                },
                labelLine: {
                    show: false
                },
                color: "rgba(0,0,0,0)",
                borderWidth: 0
            },
            emphasis: {
                color: "rgba(0,0,0,0)",
                borderWidth: 0
            }
        }
        var dataStyle = {
            normal: {
                formatter: '{c}%',
                position: 'center',
                show: true,
                textStyle: {
                    fontSize: '40',
                    fontWeight: 'normal',
                    color: '#34374E'
                }
            }
        }
        const option = {
            backgroundColor: '#fff',
            title: [{
                text: props.ringTitle,
                left: '50%',
                top: '60%',
                textAlign: 'center',
                textStyle: {
                    fontWeight: 'normal',
                    fontSize: '16',
                    color: '#AAAFC8',
                    textAlign: 'center',
                },
            }],
            series: [{
                    type: 'pie',
                    hoverAnimation: false,
                    radius: ['60%', '70%'],
                    center: ['50%', '40%'],
                    startAngle: 225,
                    labelLine: {
                        normal: {
                            show: false
                        }
                    },
                    label: {
                        normal: {
                            position: 'center'
                        }
                    },
                    data: [{
                            value: 100,
                            itemStyle: {
                                normal: {
                                    color: props.ringColor[1]
                                }
                            },
                        }, {
                            value: 35,
                            itemStyle: placeHolderStyle,
                        },
        
                    ]
                },
                {
                    type: 'pie',
                    hoverAnimation: false,
                    radius: ['60%', '70%'],
                    center: ['50%', '40%'],
                    startAngle: 225,
                    labelLine: {
                        normal: {
                            show: false
                        }
                    },
                    label: {
                        normal: {
                            position: 'center'
                        }
                    },
                    data: [{
                            value: props.ringData[0],
                            itemStyle: {
                                normal: {
                                    color: props.ringColor[0]
                                }
                            },
                            label: dataStyle,
                        }, {
                            value: props.ringData[1],
                            itemStyle: placeHolderStyle,
                        },
        
                    ]
                },
            ]
        }
        myEchart.setOption(option)
        window.addEventListener('resize', () => {
            myEchart.resize()
        })
    }

    useEffect(() => {
        drawRing()
    })

    return (
       <div {...css(styles.borderStyle)} id={props.ringId}></div>
    )
}

const styles = {
    borderStyle: {
        width:"100%",
        height:"100%"
    },
  }