import React from "react"
import { Row, Col } from "reactstrap"
import Layout from "../../components/layout/layout"
import Content from "../../components/content/content"
import Header from "../../components/header/header"
import { useUserOrgs } from "../../hooks/use-user"
import { Card, CardHeader, Avatar, CardContent } from "@material-ui/core"
import EchartsRing from "../../components/metrics/metrics-echarts/echarts-ring"
import { Loading } from "../../components/loading"

const SystemPage = () => {
  const { loading } = useUserOrgs()

  if (loading) {
    return <Loading />
  }
  const gitlabData = [5, 95]
  const artifactoryData = [5, 95]
  const gitlabColor = ["#E37373", "#f3ebeb"]
  const artifactoryColor = ["#C2C319", "#ececd9"]

  return (
    <Layout pageTitle="System Dashboard">
      <Header contentPage={true} className="contentPage">
        <h1 className="display-4 font-weight-bold">System Dashboard</h1>
      </Header>
      <Content>
        <Row>
          <Col>
            <Card>
              <CardHeader
                title="Git Statistics"
                subheader="50/1000（GB）"
                avatar={
                  <Avatar aria-label="recipe" style={{ background: "#E37373" }}>
                    G
                  </Avatar>
                }
              ></CardHeader>

              <CardContent style={{ width: "100%", height: 260 }}>
                <EchartsRing
                  ringId="gitlab"
                  ringData={gitlabData}
                  ringTitle="Git"
                  ringColor={gitlabColor}
                ></EchartsRing>
              </CardContent>
            </Card>
          </Col>
          <Col>
            <Card>
              <CardHeader
                title="Artifacts Statistics"
                subheader="500/10000（GB）"
                avatar={
                  <Avatar aria-label="recipe" style={{ background: "#C2C319" }}>
                    A
                  </Avatar>
                }
              ></CardHeader>
              <CardContent style={{ width: "100%", height: 260 }}>
                <EchartsRing
                  ringId="artifactory"
                  ringData={artifactoryData}
                  ringTitle="Artifacts"
                  ringColor={artifactoryColor}
                ></EchartsRing>
              </CardContent>
            </Card>
          </Col>
        </Row>
      </Content>
    </Layout>
  )
}

export default SystemPage
